import React from "react";

function PrivacyPolicy() {
  return (
    <div className="privacyPolicy infoPage">
      <div container="infoPage">
        <h1>Privacy Policy</h1>
        <h1>Privacy Policy for Funker530</h1>
        <h2>INTRODUCTION</h2>
        <p>
          This Website (“Platform”) and its contents are owned by Funker530
          ("we," "us," or "our"), along with third-party vendors contracted by
          us to run and maintain the functionality of our Platform. We are
          committed to protecting the privacy of those use our Platform.
        </p>
        <p>
          This Privacy Policy describes the information we collect, how we use
          it, and your options as you interact with the Platform. By using this
          Platform, you agree to this Privacy Policy. Please read the following
          carefully before using the Platform.
        </p>
        <p>
          Please note that this Privacy Policy does not govern the collection
          and use of information by companies that we do not control, nor by
          individuals not employed or managed by us. If you visit another
          website outside of our Platform (whether recommended, referenced, or
          linked), we strongly encourage you to review that website’s privacy
          policy before providing that website your information. This Privacy
          Policy applies only to our Platform.
        </p>
        <h2>YOUR CONSENT</h2>
        <p>
          By using our Platform, you consent to the collection, use, and sharing
          of information as described in this Privacy Policy. By providing us
          with an email address when you create an account and/or subscribe to
          our premium community, request to be contacted, and/or make a purchase
          in the online store, you agree that we may use that email address to
          communicate with you about our Platform and select messages from our
          partners or affiliates.
        </p>
        <p>
          NOTE: Opt-out options are discussed in the section entitled
          “Third-Party Vendors and Ways to Opt Out”.
        </p>
        <h2>INFORMATION WE COLLECT</h2>
        <p>
          You may use our Platform without providing any personal information.
          When you reply to any of our communications with a request, we may
          need to collect additional information. For example, you may be asked
          to provide personal identifiable information (PII), such as first and
          last name, email address, phone number, and shipping or billing
          address.
        </p>
        <p>
          If you submit any personal information relating to other people, you
          represent that you have the authority to do so and permit us to use
          the information in accordance with this Privacy Policy. By submitting
          personal information, you grant us the right to transmit, monitor,
          retrieve, store, and use your information in connection with the
          operation of the Platform.
        </p>
        <p>
          We, along with contracted third-party vendors and advertisers, may
          also collect certain information by automated means, such as cookies
          and web beacons. A “cookie” is a text file that websites send to a
          visitor’s computer or other Internet-connected device to uniquely
          identify the visitor’s browser or to store information or settings in
          the browser. A “web beacon,” also known as an Internet tag, pixel tag,
          or clear graphics interchange format (GIF), is used to transmit
          information about actions of the user opening the page or email
          containing the beacon back to a web server. Certain features of the
          Platform may use local shared objects (or Flash cookies) to collect
          and store information about your preferences and how you interact with
          the Platform. We may also use third-party analytics tools (e.g.,
          Google Analytics) that separately stores and collects information
          about use of the Platform and user traffic. The information we may
          collect by automated means may include, without limitation:
        </p>
        <ul>
          <li>
            Information about the devices you use to access the Platform (such
            as your computer’s domain name, the IP address and the type of the
            device, operating system, and web browser).
          </li>
          <li>· Referring and exit pages.</li>
          <li>
            Dates, times, and duration of use of the Platform (including whether
            you are a repeat or first-time visitor).
          </li>
          <li>
            Information on actions taken when using the Platform (e.g.,
            clickstream data, such as page views and navigation patterns).
          </li>
        </ul>
        <p>
          This information helps us to improve and personalize our Platform,
          focus our marketing efforts more precisely, and helps us identify and
          authenticate users.
        </p>
        <h2>HOW WE USE THIS INFORMATION</h2>
        <p>
          You have the right to choose whether to disclose PII to us. For the
          purposes of this Privacy Policy, PII is information that can be used
          to identify you such as:
        </p>
        <ul>
          <li>
            Your first and last name, email address, phone number, billing
            address, and shipping address.
          </li>
          <li>Your user ID and password.</li>
          <li>Any account-preference information you provide.</li>
          <li>
            Your computer's domain name and IP address, indicating where your
            computer is located on the Internet.
          </li>
          <li>
            Session data for your login session so that our Platform systems can
            'talk' to your computer while you are logged in or actively using
            the Platform.
          </li>
        </ul>
        <p>The PII you provide us may be used to:</p>
        <ul>
          <li>Process and fulfill online transations and/or requests.</li>
          <li>
            Respond to customer service inquiries, questions, and requests to
            improve customer service.
          </li>
          <li>
            Personalize your experience on the Platform and inform you about
            products/services in which you have indicated an interest.
          </li>
          <li>
            Tailor digital advertisements on third-party websites based on your
            interactions on our Platform.
          </li>
          <li>
            Improve our Platform functionality, performance, and offerings.
          </li>
          <li>
            Provide aggregated statistical information, in a way that your
            identity cannot be determined.
          </li>
          <li>
            Comply with a legal request, such as a subpoena or search warrant.
          </li>
          <li>
            Respond to an external auditor that has agreed to keep the
            information confidential.
          </li>
          <li>Enforce applicable Terms of Service.</li>
          <li>
            Protect the rights, safety, or property of our Platform, its users,
            or others, such as during the exchange of information with other
            organizations for the purposes of fraud protection and/or risk
            reduction.
          </li>
          <li>Administer a promotion, survey, or other Platform feature.</li>
          <li>
            Communicate with you about changes to our Privacy Policy,
            promotions, and/or support.
          </li>
        </ul>
        <h2>THIRD-PARTY VENDORS AND WAYS TO OPT-OUT</h2>
        <p>
          By providing your PII to us, either directly or through a reseller or
          other business partner, we will:
        </p>
        <ul>
          <li>
            Not sell or rent it to a third-party without your permission. Unless
            you opt-out by sending us an email at admin@funker530.com, we may
            use your contact information to provide you with information we
            believe you need to know or may find useful, such as news about our
            Platform and products and modifications to the Terms of Service
            and/or Privacy Policy.
          </li>
          <li>
            Take commercially reasonable precautions to protect the information
            from loss, misuse, unauthorized access, disclosure, alteration, and
            destruction.
          </li>
        </ul>
        <p>
          In the course of our business, we may share information with
          third-parties, including as required by law or in order to provide the
          services requested. We engage reputable third-party vendors to help us
          manage this Platform, provide content and information, and interact
          better with our customers.
        </p>
        <p>
          Although we do not control how third-parties collect or use your
          information to serve interest-based advertising or other targeted
          content, a number of these third-parties may provide you with ways to
          choose not to have your information collected or used in this way.
        </p>
        <h2>ACCESSING AND CHANGING YOUR INFORMATION</h2>
        <p>
          You may send us an email at admin@funker530.com to request access to,
          correct, and/or delete any personal information and/or consent to
          process your PII that you have provided to us. We may not accommodate
          a request to change or delete personal information if we believe the
          change or deletion would violate any law or legal requirement, cause
          the information to be incorrect, and/or cause performance degredation
          to the Platform.
        </p>
        <h2>YOUR PRIVACY RESPONSIBILITIES AND RIGHTS</h2>
        <p>To help protect your privacy, be sure:</p>
        <ul>
          <li>Not to share your user ID or password with anyone else.</li>
          <li>To log off our Platform when you are finished.</li>
          <li>
            To take customary precautions to guard against "malware" (viruses,
            Trojan horses, bots, etc.) by installing and updating your laptop
            and mobile devices with suitable anti-virus software.
          </li>
        </ul>
        <p>Depending on your jurisdiction, you may have rights to:</p>
        <ul>
          <li>Access and update your personal information.</li>
          <li>Request the deletion of your personal information.</li>
          <li>Opt-out of marketing communications.</li>
          <li>File a complaint with regulatory authorities.</li>
        </ul>
        <p>
          To exercise your rights, please contact us at admin@funker530.com.
        </p>
        <h2>CHILDREN’S PRIVACY</h2>
        <p>
          Our Platform is not directed towards, nor do we knowingly collect or
          solicit personal information from, children under the age of 13 in the
          United States or under the age of 16 in Canada (“Underage Child”). We
          strongly encourage parents and guardians to:
        </p>
        <ul>
          <li>
            Observe, participate in, and monitor their Underage Child’s online
            activity.
          </li>
          <li>
            Contact us immediately if you believe we have inadvertently
            collected personal information from an Underage Child without proper
            consent.
          </li>
        </ul>
        <p>
          If we learn we have collected or received personal information from an
          Underage Child, we will delete that information. If you are a parent
          or guardian and wish to request the deletion of personal information
          about an Underage Child, please contact us at admin@funker530.com.
        </p>
        <h2>COMPLIANCE WITH RELEVANT LAWS</h2>
        <p>This Privacy Policy adheres to:</p>
        <ul>
          <li>
            Canadian Laws: Including the Personal Information Protection and
            Electronic Documents Act (PIPEDA).
          </li>
          <li>
            United States Laws: Including the Children’s Online Privacy
            Protection Act (COPPA) and relevant state regulations.
          </li>
        </ul>
        <p>
          We strive to meet all applicable privacy and data protection
          requirements.
        </p>
        <h2>NOTICE TO USERS IN THE EUROPEAN UNION</h2>
        <p>
          Currently, our operations and customers are located primarily in
          Canada and the United States. This Privacy Policy does not apply to
          users who are in the European Union (EU) or the European Economic Area
          (EEA), as they are subject to different data protection laws and
          regulations. If you are an EU or EEA user, please do not use this
          Platform or provide any personal information to us.
        </p>
        <h2>CHANGES TO THIS POLICY</h2>
        <p>
          We reserve the right to update or change this Privacy Policy at any
          time. If we make a material change to our Privacy Policy, we will
          provide notice by posting on our Platform that our Privacy Policy has
          changed along with the relevant changes.
        </p>
        <p>
          In the event we make a material change to how we use your PII, we will
          provide you with an opportunity to opt-out of such new or different
          use. The effective date of this Privacy Policy the the last date it
          was last updated is at the top of this page. We encourage you to
          review this Privacy Policy periodically to check for any updates or
          changes.
        </p>
        <h2>CONTACT US</h2>
        <p>
          If you have questions or comments about this Privacy Policy or how
          personal information is used, email us at admin@funker530.com.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
