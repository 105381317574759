const useDevAPI =
  window.location.hostname.includes("dev") ||
  window.location.hostname.includes("localhost") ||
  window.location.hostname.includes("azurestaticapps.net");

export const AD_BASE_URL = useDevAPI
  ? "https://funker530-ads-staging.azurewebsites.net/api/GetBannerAd?code=bKpRLmbWxP3pqXAN6kqSIqjPmspDCNjOXH8h4BYiWiZYAzFu_7w9pA=="
  : "https://green.funker530.com/api/GetBannerAd?code=sMYsoM1YowbUGryzG47fyGhv7JQ8GFm85SottVJJg3/AhtnBec8nzg==";
export const API_URL =
  process.env.API_LOCATION === "local"
    ? "http://localhost:7071/api/"
    : useDevAPI
      ? "https://funker530-fnc-staging.azurewebsites.net/api/"
      : "https://api.funker530.com/api/";
export const API_CODE = useDevAPI
  ? "vuqwRsFKwFlKIJ5lWpM7mq7pjzpOTWbHSmrz3aacsuasAzFu85TPoQ=="
  : "sL3mjD-c0BJdI9b9h4s7WhIPU8ca9p6h3yiLyFczS-I9AzFupvbo9g==";
// export const API_CODE =
//   "sL3mjD-c0BJdI9b9h4s7WhIPU8ca9p6h3yiLyFczS-I9AzFupvbo9g==";
export const IMAGES_BASE_URL = "https://images.funker530.com/images/media/";
export const DEFAULT_AVATAR_IMAGE = "anon.png";
export const MAIN_SITE_URL = "https://funker530.com";
